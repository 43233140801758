<template>
  <div class="container-md col-lg-11 col-md-11 customer-list pb-4 mb-4">
    <LoadingOverlay v-if="isCustomerFetch" />
    <div v-else>
      <h3 class="text-primary">Customer List</h3>
      <CDataTable
        :striped="striped"
        :bordered="bordered"
        :small="small"
        :items="customers"
        :fields="fields"
        :items-per-page="small ? limit : 5"
        sorter
        :tableFilter="{ label: 'Search', placeholder: 'Type for search' }"
        pagination
        class="px-1 border"
      >
        <template #action="{ item, index }">
          <td class="py-2">
            <div class="d-flex" v-if="item.customer_type_id === 2">
              <CButton
                color="primary"
                square
                size="sm"
                class="mr-1"
                @click="editUser(item, index)"
                v-c-tooltip="'Edit'"
                ><em class="far fa-edit"></em
              ></CButton>
              <CButton
                v-show="collapseIndex === index"
                color="primary"
                square
                variant="outline"
                size="sm"
                class="mr-1"
                @click="toggleDetails(item, index)"
                v-c-tooltip="{
                  content: 'Collapse all Organizations!',
                  placement: 'left',
                  appendToBody: true,
                }"
              >
                <em class="fa fa-minus"></em>
              </CButton>
              <CButton
                v-show="collapseIndex !== index"
                color="primary"
                square
                variant="outline"
                size="sm"
                class="mr-1"
                @click="toggleDetails(item, index)"
                v-c-tooltip="{
                  content: 'List all Organizations!',
                  placement: 'left',
                  appendToBody: true,
                }"
              >
                <em class="fa fa-plus"></em>
              </CButton>
              <CButton
                v-show="item.customer_type_id === 2"
                color="primary"
                square
                variant="outline"
                size="sm"
                class="mr-1"
                v-c-tooltip="{
                  content: 'Edit associated Organisations',
                  placement: 'left',
                  appendToBody: true,
                }"
                @click="organizationUpdateForAgency(item, index)"
              >
                <em class="fa fa-cogs"></em>
              </CButton>
              <CButton
                color="primary"
                shape="square"
                variant="outline"
                size="sm"
                v-c-tooltip="{
                  content: 'Config Theme',
                  placement: 'bottom',
                  appendToBody: true,
                }"
                @click="openConfigTheme(item)"
              >
                <em class="fas fa-tools"></em>
              </CButton>
            </div>
            <div class="d-flex" v-else>
              <CButton
                color="primary"
                square
                size="sm"
                class="mr-1"
                @click="editUser(item, index)"
                v-c-tooltip="'Edit'"
                ><em class="far fa-edit"></em
              ></CButton>
              <CButton
                v-show="collapseIndex === index"
                color="primary"
                square
                variant="outline"
                size="sm"
                class="mr-1"
                @click="toggleDetails(item, index)"
                v-c-tooltip="{
                  content: 'Collapse all child Organizations!',
                  placement: 'left',
                  appendToBody: true,
                }"
              >
                <em class="fa fa-minus"></em>
              </CButton>
              <CButton
                v-show="collapseIndex !== index"
                color="primary"
                square
                variant="outline"
                size="sm"
                class="mr-1"
                @click="toggleDetails(item, index)"
                v-c-tooltip="{
                  content: 'List all child Organizations!',
                  placement: 'left',
                  appendToBody: true,
                }"
              >
                <em class="fa fa-plus"></em>
              </CButton>
              <CButton
                color="primary"
                shape="square"
                variant="outline"
                size="sm"
                v-c-tooltip="{
                  content: 'Config Theme',
                  placement: 'bottom',
                  appendToBody: true,
                }"
                @click="openConfigTheme(item)"
              >
                <em class="fas fa-tools"></em>
              </CButton>
            </div>
          </td>
        </template>
        <template #details="{ item, index }">
          <CCollapse
            :show="collapseIndex === index"
            :duration="collapseDuration"
          >
            <h6 class="p-2 text-primary">
              {{ item.institution_name }} -
              {{
                item.customer_type_id === 2
                  ? "Child & Associated Organisations"
                  : "Child Organizations"
              }}
              ({{ organisationsFilteredList.length || "0" }} Record{{
                organisationsFilteredList.length > 1 ? "s" : ""
              }})
              <CSpinner
                v-if="isOrganisationFetch"
                color="primary"
                class="mx-5"
                style="width: 2rem; height: 2rem"
              />
              <CButton
                color="primary"
                shape="square"
                variant="outline"
                size="sm"
                class="px-5 m-3 mt-3"
                @click="addOrg()"
              >
                <em class="fa fa-plus"></em>
                Add Child Organisation
              </CButton>
            </h6>
            <CTabs
              class="nav-fill nav-justified"
              v-if="item.customer_type_id == 2"
            >
              <CTab title="Child Organisations">
                <OrganisationList
                  v-if="organisationListModal.isShow"
                  :parent_org_id="organisationListModal.parent_org_id"
                  :customer_uid="organisationListModal.customer_uid"
                  :is_recruiter_agency="
                    organisationListModal.is_recruiter_agency
                  "
                  :show_associated_orgs="false"
                  @closeModal="organisationModalCallBack"
                />
              </CTab>
              <CTab title="Associated Organisations">
                <OrganisationList
                  v-if="organisationListModal.isShow"
                  :parent_org_id="organisationListModal.parent_org_id"
                  :customer_uid="organisationListModal.customer_uid"
                  :is_recruiter_agency="
                    organisationListModal.is_recruiter_agency
                  "
                  :show_associated_orgs="true"
                  @closeModal="organisationModalCallBack"
                />
              </CTab>
            </CTabs>
            <div v-else>
              <OrganisationList
                v-if="organisationListModal.isShow"
                :parent_org_id="organisationListModal.parent_org_id"
                :customer_uid="organisationListModal.customer_uid"
                :is_recruiter_agency="organisationListModal.is_recruiter_agency"
                @closeModal="organisationModalCallBack"
              />
            </div>
          </CCollapse>
        </template>
      </CDataTable>
    </div>
    <CustomerEdit
      v-if="customerEditModal.isShowPopup"
      :customerDetail="customerEditModal.data"
      :isShowPopup="customerEditModal.isShowPopup"
      @modalCallBack="customerModalCallBack"
    />
    <RecruiterAgencyAction
      v-if="recruiterAgencyModal.isShowPopup"
      :parent_org_id="recruiterAgencyModal.parent_org_id"
      :customer_uid="recruiterAgencyModal.customer_uid"
      :isShowPopup="recruiterAgencyModal.isShowPopup"
      @modalCallBack="agencyModalCallBack"
    />
    <ConfigTheme
      v-if="configTheme.isShowPopup"
      :orgData="configTheme.item"
      :isShowPopup="configTheme.isShowPopup"
      :configModalCallBack="configModalCallBack"
      :updateOrgThemeManually="updateOrgThemeManually"
      :showVariant="true"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CustomerEdit from "@/containers/Customer/EditCustomer";
import RecruiterAgencyAction from "@/containers/Customer/RecruiterAgencyModal";
import OrganisationList from "@/containers/Customer/OrganisationList";
import ConfigTheme from "@/containers/Customer/ConfigTheme.vue";

export default {
  components: {
    CustomerEdit,
    OrganisationList,
    RecruiterAgencyAction,
    ConfigTheme,
  },
  data() {
    return {
      collapseDuration: 0,
      collapseIndex: null,
      limit: 5,
      small: true,
      striped: true,
      bordered: true,
      sorter: { external: true, resetable: true },
      fields: [
        {
          key: "institution_name",
          label: "Institution Name",
          _style: "width:20%",
        },
        { key: "path_acronym", label: "Path Acronym", _style: "width:10%" },
        {
          key: "parent",
          _style: "width:5%",
        },
        { key: "customer_type", label: "Customer Type", _style: "width:10%" },
        { key: "city", _style: "width:15%" },
        {
          key: "main_contact_name",
          label: "Main Contact Name",
          _style: "width:15%;white-space:nowrap",
        },
        { key: "email", label: "Email", _style: "width:15%" },
        {
          key: "action",
          label: "Action",
          sorter: false,
          filter: false,
          _style: "width:10%",
        },
      ],
      customerEditModal: {
        data: null,
        isShowPopup: false,
      },
      organisationListModal: {
        isShow: false,
        customer_uid: null,
        parent_org_id: null,
        is_recruiter_agency: false,
      },
      recruiterAgencyModal: {
        isShowPopup: false,
        data: null,
      },
      configTheme: {
        isShowPopup: false,
        orgData: {},
      },
      customer_uid: null,
      parent_org_id: null,
      isRecruiterAgency: false,
    };
  },
  computed: {
    ...mapGetters([
      "customerList",
      "isCustomerFetch",
      "organisationList",
      "isOrganisationFetch",
    ]),
    customers() {
      return (
        (this.customerList &&
          this.customerList.length &&
          this.customerList
            .map((val) => ({
              main_contact_name: `${val.main_user?.first_name || ""} ${
                val.main_user?.surname || ""
              }`,
              email: val.main_user?.email || "--",
              institution_name: val.name || "--",
              customer_type: val.customer_type?.comments || "--",
              main_contact_id: val.main_contact_id,
              main_user_id: val.main_user_id,
              customer_uid: val?.customer_uid,
              parent_org_id: val?.main_organisation_id,
              parent: val?.main_organisation?.is_parent ? "Yes" : "No",
              is_parent: val?.main_organisation?.is_parent,
              website: val?.main_organisation?.website,
              city: val?.customer_organisation
                ? val?.customer_organisation
                    .map((e) =>
                      e?.customer_organisation_location.map(
                        (loc) => loc?.location?.location
                      )
                    )
                    .join(", ")
                : "--",
              customer_type_id: val?.customer_type_id,
              path_acronym: val?.path_acronym || "--",
              organisation_id: val?.main_organisation_id,
              theme_style: val?.main_organisation?.theme_style,
            }))
            .reverse()) ||
        []
      );
    },
    organisationsFilteredList() {
      return (
        (this.organisationList.length &&
          this.organisationList.filter((val) => {
            return val.organisation_id != this.parent_org_id;
          })) ||
        []
      );
    },
  },
  methods: {
    ...mapActions([
      "fetchCustomerList",
      "updateCustomer",
      "fetchOrganisationListForCustomer",
      "addRecruitingAgencyOrganisations",
      "deleteRecruitingAgencyOrganisations",
    ]),
    addOrg() {
      this.tooltipHide();
      this.$router.push({
        path: `/add-child-organisation/customer/${this.customer_uid}/org/${this.parent_org_id}`,
      });
    },
    toggleDetails(item, index) {
      item.toggle = true;
      if (this.collapseIndex === index) {
        this.collapseIndex = null;
        this.tooltipHide();
        return;
      }
      this.collapseIndex = index;
      this.openOrganisationModel(item);
    },
    editUser(data, index) {
      this.tooltipHide();
      let customerData =
        this.customerList[
          this.customerList.findIndex(
            (e) => e.main_user_id === data.main_user_id
          )
        ];
      this.customerEditModal.isShowPopup = true;
      this.customerEditModal.data = customerData;
      this.customerEditModal.customerID = data.customer_uid;
    },
    customerModalCallBack(action, payload) {
      if (action === false) {
        this.customerEditModal.isShowPopup = false;
        this.customerEditModal.data = null;
      } else if (action === true) {
        let appendAction = this.updateCustomer(payload);
        Promise.all([appendAction]).then((res) => {
          if (res) {
            this.customerEditModal.isShowPopup = false;
            this.customerEditModal.data = null;
            this.fetchCustomerList();
          }
        });
      }
    },
    updateOrgThemeManually(data) {
      for (let i = 0; i < this.customers.length; i++) {
        if (this.customers[i].organisation_id == data.organisation_id) {
          if (data?.theme_style) {
            this.customers[i].theme_style = data.theme_style;
          }
          if (data?.website) {
            this.customers[i].website = data.website;
          }
        }
      }
    },
    openOrganisationModel(item) {
      this.isRecruiterAgency = false;
      if (item.customer_type_id == 2) {
        this.isRecruiterAgency = true;
      }
      this.customer_uid = item?.customer_uid;
      this.parent_org_id = item?.parent_org_id;
      this.tooltipHide();
      this.organisationListModal = {
        customer_uid: item?.customer_uid,
        parent_org_id: item?.parent_org_id,
        is_recruiter_agency: this.isRecruiterAgency,
        isShow: true,
      };
      let payload = {
        customer_uid: item.customer_uid,
      };
      if (!this.isRecruiterAgency) {
        payload.parent_org_id = item.parent_org_id;
      }
      this.fetchOrganisationListForCustomer(payload);
    },
    organisationModalCallBack(action) {
      this.organisationListModal.isShow = false;
    },
    tooltipHide() {
      setTimeout(() => {
        let x = document.querySelectorAll(".tooltip");
        for (var i = 0; i < x.length; i++) {
          x[i].style.visibility = "hidden";
        }
      }, 50);
    },
    organizationUpdateForAgency(item, index) {
      this.collapseIndex = null;
      this.tooltipHide();
      this.recruiterAgencyModal.isShowPopup = true;
      this.recruiterAgencyModal.customer_uid = item?.customer_uid;
      this.recruiterAgencyModal.parent_org_id = item?.parent_org_id;
    },
    agencyModalCallBack(action, payload) {
      if (action) {
        if (payload?.create) {
          this.addRecruitingAgencyOrganisations({
            customer_id: this.recruiterAgencyModal.customer_uid,
            data: payload?.payload,
          });
        } else {
          this.deleteRecruitingAgencyOrganisations({
            customer_id: this.recruiterAgencyModal.customer_uid,
            data: payload?.payload,
          });
        }
      }
      this.recruiterAgencyModal.isShowPopup = false;
    },
    openConfigTheme(item) {
      this.tooltipHide();
      this.configTheme.item = item;
      this.configTheme.isShowPopup = true;
    },
    configModalCallBack() {
      this.configTheme.isShowPopup = false;
    },
  },
  mounted() {
    this.fetchCustomerList();
  },
};
</script>
