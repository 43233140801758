<template>
  <CModal
    color="primary"
    :show.sync="isShowPopup"
    :closeOnBackdrop="false"
    size="lg"
  >
    <template #header>
      <h5>Subscription</h5>
      <CButtonClose @click="configModalCallBack()" />
    </template>
    <template #footer>
      <CButton color="secondary" @click="configModalCallBack()">Close</CButton>
    </template>

    <div style="min-height: 400px" class="position-relative">
      <h5 style="font-weight: 700">
        {{
          orgData.institution_name
            ? orgData.institution_name
            : orgData.organisation_name
        }}
        {{ path_acronym ? ` (${path_acronym})` : "" }}
      </h5>
      <CRow
        class="m-3 justify-content-center"
        v-if="showVariant && isFetchingData"
      >
        <div
          class="option m-2"
          v-for="(item, index) in options.variant"
          :key="index"
        >
          <label :class="item.id == payload.variant ? 'active' : ''">
            <input
              type="radio"
              :value="item.id"
              :checked="item.id == payload.variant"
              @input="handleRadio('variant', item)"
            />
            {{ item.label }}
          </label>
        </div>
      </CRow>
      <CRow
        class="justify-content-end"
        v-if="isDataUpdated"
        style="bottom: 0px; position: absolute; width: 100%"
      >
        <CButton class="btn-secondary mr-3" @click="cancel()">Cancel</CButton>
        <CButton class="btn-primary" @click="updateVariant()">Update</CButton>
      </CRow>
      <!-- <CTabs
        variant="tabs"
        :fill="true"
        :justified="true"
        :active-tab="activeTab"
        @update:activeTab="updateTab"
      >
        <CTab title="Header Logo" :disabled="!path_acronym || isDataUpdated">
          <ConfigThemeFiles
            v-if="activeTab == 0"
            documentName="header_logo_url"
            :orgData="orgData"
            @dataUpdated="updateDataChange"
            :updateOrgThemeManually="updateOrgThemeManually"
          />
        </CTab>
        <CTab title="Login Logo" :disabled="!path_acronym || isDataUpdated">
          <ConfigThemeFiles
            v-if="activeTab == 1"
            documentName="login_logo_url"
            :orgData="orgData"
            @dataUpdated="updateDataChange"
            :updateOrgThemeManually="updateOrgThemeManually"
          />
        </CTab>
        <CTab title="Plain Logo" :disabled="!path_acronym || isDataUpdated">
          <ConfigThemeFiles
            v-if="activeTab == 2"
            documentName="customer_logo_url"
            :orgData="orgData"
            @dataUpdated="updateDataChange"
            :updateOrgThemeManually="updateOrgThemeManually"
          />
        </CTab>
        <CTab
          title="Landing Page Image"
          :disabled="!path_acronym || isDataUpdated"
        >
          <ConfigThemeFiles
            v-if="activeTab == 3"
            documentName="banner_path_url"
            :orgData="orgData"
            @dataUpdated="updateDataChange"
            :updateOrgThemeManually="updateOrgThemeManually"
          />
        </CTab>
        <CTab title="Theme" :disabled="!path_acronym || isDataUpdated">
          <div class="p-2">
            <CRow class="m-3 justify-content-center">
              <div
                class="option m-2"
                v-for="(item, index) in options.theme_style"
                :key="index"
              >
                <label :class="item.id == payload.theme_style ? 'active' : ''">
                  <input
                    type="radio"
                    :value="item.id"
                    :checked="item.id == payload.theme_style"
                    @input="handleRadio('theme_style', item)"
                  />
                  <td>
                    <ul class="p-2" style="list-style-type: none">
                      <li
                        :style="`background-color:${item.primary_color}; color: #fff;`"
                        class="theme_li p-2"
                      >
                        <small>Primary</small>
                        <small class="ml-2">{{ item.primary_color }}</small>
                      </li>
                      <li
                        :style="`background-color: ${item.secondary_color}; color: black; width: 150px`"
                        class="theme_li p-2"
                      >
                        <small>Secondary</small>
                        <small class="ml-2">{{ item.secondary_color }}</small>
                      </li>
                    </ul>
                  </td>
                </label>
              </div>
            </CRow>
            <CRow
              class="justify-content-end"
              v-if="isDataUpdated"
              style="bottom: 0px; position: absolute; width: 100%"
            >
              <CButton class="btn-secondary mr-3" @click="cancel()"
                >Cancel</CButton
              >
              <CButton class="btn-primary" @click="updateThemeStyle()"
                >Update</CButton
              >
            </CRow>
          </div>
        </CTab>
        <CTab
          title="Variant"
          v-if="showVariant && isFetchingData"
          :disabled="isDataUpdated"
          class=""
        >
          <CRow class="m-3 justify-content-center">
            <div
              class="option m-2"
              v-for="(item, index) in options.variant"
              :key="index"
            >
              <label :class="item.id == payload.variant ? 'active' : ''">
                <input
                  type="radio"
                  :value="item.id"
                  :checked="item.id == payload.variant"
                  @input="handleRadio('variant', item)"
                />
                {{ item.label }}
              </label>
            </div>
          </CRow>
          <CRow
            class="justify-content-end"
            v-if="isDataUpdated"
            style="bottom: 0px; position: absolute; width: 100%"
          >
            <CButton class="btn-secondary mr-3" @click="cancel()"
              >Cancel</CButton
            >
            <CButton class="btn-primary" @click="updateVariant()"
              >Update</CButton
            >
          </CRow>
        </CTab>
      </CTabs> -->
    </div>
  </CModal>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/reusable/Modal";
import ConfigThemeFiles from "./ConfigThemeFiles.vue";

export default {
  components: { ConfigThemeFiles, Modal },
  extends: Modal,
  name: "ConfigTheme",
  props: [
    "orgData",
    "configModalCallBack",
    "updateOrgThemeManually",
    "showVariant",
  ],
  data() {
    return {
      updateModal: true,
      activeTab: 0,
      payload: {
        theme_style: null,
        variant: "",
      },
      isDataUpdated: false,
      path_acronym: "",
    };
  },
  computed: {
    ...mapGetters(["getSelectedCustomerVariant"]),
    options() {
      return {
        theme_style: [
          {
            id: "theme_default",
            primary_color: "#dd3651",
            secondary_color: "#b8b8b8",
          },
          {
            id: "theme_dark_cyan",
            primary_color: "#0094a8",
            secondary_color: "#d9f0f3",
          },
          {
            id: "theme_blue",
            primary_color: "#2daae1",
            secondary_color: "#f0f0f0",
          },
          {
            id: "theme_tfw",
            primary_color: "#cb126b",
            secondary_color: "#fde6f2",
          },
          {
            id: "theme_dark_blue",
            primary_color: "#006ec7",
            secondary_color: "#dae1f3",
          },
        ],
        variant: [
          { id: "BASIC", label: "Basic" },
          { id: "STANDARD", label: "Standard" },
          { id: "ENTERPRISE", label: "Enterprise" },
        ],
      };
    },
    isFetchingData() {
      this.payload.variant = this.getSelectedCustomerVariant;
      return true;
    },
  },
  methods: {
    ...mapActions([
      "fetchConfigThemeFiles",
      "updateOrganisation",
      "getCustomerVariant",
      "updateCustomerVariant",
    ]),
    updateTab(value) {
      this.activeTab = value;
    },
    handleRadio(name, value) {
      let val = null;
      if (value.id === false || value.code === false) {
        val = false;
      } else if (value.id === null || value.code === null) {
        val = null;
      } else {
        val = value.id || value.code || value;
      }
      this.payload[name] = val;
      this.isDataUpdated = true;
    },
    updateThemeStyle() {
      let finalPayload = {
        organisation_id: this.orgData?.organisation_id,
        theme_style:
          this.payload.theme_style == "theme_default"
            ? null
            : this.payload.theme_style,
      };
      this.updateOrganisation(finalPayload).then((res) => {
        this.updateOrgThemeManually({
          theme_style: this.payload.theme_style,
          organisation_id: this.orgData?.organisation_id,
        });
        this.orgData.theme_style = this.payload.theme_style;
      });
      this.isDataUpdated = false;
    },
    updateVariant() {
      this.updateCustomerVariant({
        customer_id: this.orgData?.customer_uid,
        key: "VARIANT",
        value: this.payload.variant,
      });
      this.isDataUpdated = false;
    },
    cancel() {
      this.payload.theme_style = this.orgData?.theme_style
        ? this.orgData?.theme_style
        : "theme_default";
      this.payload.variant = this.getSelectedCustomerVariant;
      this.isDataUpdated = false;
    },
    updateDataChange(value) {
      this.isDataUpdated = value;
    },
  },
  mounted() {
    if (this.orgData?.path_acronym != "--") {
      this.path_acronym = this.orgData?.path_acronym;
      this.payload.theme_style = this.orgData?.theme_style
        ? this.orgData?.theme_style
        : "theme_default";
      this.fetchConfigThemeFiles(this.orgData?.path_acronym);
    } else {
      this.activeTab = 5;
    }
    if (this.showVariant) this.getCustomerVariant(this.orgData?.customer_uid);
  },
};
</script>

<style lang="scss" scoped>
.theme_li {
  font-size: 10px;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}
.option {
  label {
    position: relative;
    display: flex;
    align-items: center;
    grid-gap: 20px;
    width: 250px;
    min-height: 62px;
    padding: 0 20px;
    border-radius: 6px;
    border: 1px solid transparent;
    background-color: transparent;
    transition: all 300ms ease-in;
    border-color: grey;

    &:hover {
      border-color: #dd3651;
      background-color: rgba(#dd3651, 0.08);
    }
  }
  .active {
    border: 2px solid #dd3651;
    background-color: rgba(#dd3651, 0.08);
    color: black;
  }
}
</style>
