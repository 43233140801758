<template>
  <div class="edit-customer">
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">Edit Customer</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          color="primary"
          variant="outline"
          class="px-4"
          @click="modalCallBack(false)"
          >Cancel</CButton
        >
        <CButton type="button" color="primary" class="px-4" @click="onSubmit"
          >Update</CButton
        >
      </template>
      <LoadingOverlay v-if="isFetchingFilter" />
      <div v-else>
        <ValidationObserver ref="editCustomer" v-slot="{ handleSubmit }">
          <form id="editCustomer" @submit.prevent="handleSubmit(onSubmit)">
            <CRow>
              <CCol lg="6">
                <CRow name="customer_type" class="mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Select the type Of User</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="customer_type_id"
                        :value="customer.customer_type_id"
                        :options="
                          options && options['customer_type']
                            ? options['customer_type']
                            : []
                        "
                        :multiple="false"
                        :clearable="false"
                        @input="handleChangeSelect"
                        :error="errors[0]"
                        :disabled="true"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>

              <CCol
                lg="6"
                v-if="
                  customer['customer_type_id'] &&
                  customer['customer_type_id']['code'] &&
                  customer['customer_type_id']['code'] === 4
                "
              >
                <CRow class="mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Organization Type</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="org_type_id"
                        :value="customer.org_type_id"
                        :options="
                          options && options['organization_type']
                            ? options['organization_type']
                            : []
                        "
                        :multiple="false"
                        :clearable="false"
                        @input="handleChangeSelect"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CRow name="institution_name" class="mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Institution Name</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="name"
                        :value="customer.name"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow name="address" class="mb-3">
                  <label class="required col-lg-12 col-md-12">Address</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="address"
                        :value="customer.address"
                        :error="errors[0]"
                        @input="handleInput"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CRow class="mb-3" name="country">
                  <label class="required col-lg-12 col-md-12">Country</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="country_ids"
                        :value="customer.country_ids"
                        :options="
                          options && options['country']
                            ? options['country']
                            : []
                        "
                        :multiple="!isParent"
                        :clearable="false"
                        @input="handleChangeMultiSelect"
                        :error="errors[0]"
                        :disabled="isParent"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow class="mb-3">
                  <label name="location" class="required col-lg-12 col-md-12"
                    >City</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="location_ids"
                        :value="customer.location_ids"
                        :options="
                          options && options['location']
                            ? options['location']
                            : []
                        "
                        :multiple="!isParent"
                        :clearable="false"
                        @input="handleChangeMultiSelect"
                        :error="errors[0]"
                        :disabled="isParent"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CRow name="district" class="mb-3">
                  <label class="col-lg-12 col-md-12">Districts</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <Select
                      name="district_ids"
                      :value="customer.district_ids"
                      :options="
                        options && options['districts']
                          ? options['districts']
                          : []
                      "
                      :multiple="!isParent"
                      :clearable="false"
                      @input="handleChangeSelect"
                      :disabled="true"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow name="email" class="mb-3">
                  <label class="required col-lg-12 col-md-12">Email</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      rules="required|email"
                      v-slot="{ errors }"
                    >
                      <TextInput
                        name="email"
                        :value="customer.email"
                        :error="errors[0]"
                        @input="handleInput"
                        :disabled="true"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";

import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import PasswordInput from "@/components/reusable/Fields/PasswordInput";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import _ from "lodash";
import { extend } from "vee-validate";
import {
  required,
  email,
  confirmed,
  regex,
  min,
  numeric,
} from "vee-validate/dist/rules";
import { mapGetters, mapActions } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import m from "moment";

extend("required", { ...required, message: "This field is required" });

extend("numeric", { ...numeric, message: "Only numbers are allowed" });
extend("phone_rule", {
  ...regex,
  message: "Only numbers are allowed(Maximum of 15 digits)",
});
extend("email", { ...email, message: "Invalid email" });
export default {
  props: {
    customerDetail: {
      type: Object,
      default: () => null,
    },
    isShowPopup: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    Select,
    TextInput,
    PhoneInput,
    PasswordInput,
    RadioButton,
  },
  data() {
    return {
      payload: {},
      confirmPassword: null,
      customer: {},
      customerId: null,
    };
  },
  computed: {
    ...mapGetters([
      "locationCountryInfo",
      "dialingCodeWithCountryCode",
      "getCustomerTypeList",
      "selectedCustomer",
      "isFetchingFilter",
      "getOrganisationTypeList",
      "getLocationByCountryID",
      "getLocationByCountrysID",
    ]),
    options() {
      return {
        customer_type: this.getCustomerTypeList || [],
        country: this.customCountry || [],
        dialingCode: this.dialingCodeWithCountryCode || [],
        location: this.customLocation || [],
        is_parent_admin: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        organization_type: this.getOrganisationTypeList || [],
      };
    },
    customCountry() {
      return Array.isArray(this.payload["country_ids"]) && !this.isParent
        ? this.locationCountryInfo.filter(
            ({ code }) => !this.payload["country_ids"].includes(code)
          )
        : this.locationCountryInfo;
    },
    customLocation() {
      let opt_location =
        this.getLocationByCountrysID({
          country_id: this.payload?.country_ids,
        }) || [];
      return Array.isArray(this.payload["location_ids"])
        ? opt_location.filter(
            ({ code }) => !this.payload["location_ids"].includes(code)
          )
        : opt_location;
    },
    isParent() {
      return this.customerDetail?.main_organisation?.is_parent;
    },
    main_organisation() {
      return this.customerDetail?.customer_organisation.find(
        ({ organisation_id }) =>
          organisation_id === this.customerDetail?.main_organisation_id
      );
    },
    main_organisation_locations() {
      let default_value = {
        country_ids: [],
        location_ids: [],
      };
      return (
        this.main_organisation?.customer_organisation_location.reduce(
          (obj, e) => {
            const country_id = e?.location?.country?.country_id;
            const location_id = e?.location?.location_id;
            if (!obj.country_ids) obj.country_ids = [];
            if (!obj.location_ids) obj.location_ids = [];

            obj.country_ids.push(country_id);
            obj.location_ids.push(location_id);
            return obj;
          },
          default_value
        ) || default_value
      );
    },
  },
  methods: {
    ...mapActions(["initUserFetchOptions", "showToast"]),
    handleInput(name, value) {
      let hasError = {
        phone: "phone",
      };
      if (name !== hasError.phone || (name == hasError.phone && value !== "")) {
        Vue.set(this.customer, name, value);
        Vue.set(this.payload, name, value);
      }
      if (name === hasError.phone && value === "") {
        this.clearFieldErrors(name, hasError);
      }
    },
    handleChangeSelect(name, value) {
      Vue.set(this.customer, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
      if (name === "customer_type_id") {
        this.handleChangeSelect("org_type_id", null);
      }
    },
    handleChangeMultiSelect(name, value) {
      const unRemovableFields = ["country_ids", "location_ids"];
      if (unRemovableFields.includes(name) && value) {
        const main_organisation = this.main_organisation;
        if (!main_organisation) {
          return;
        }
        const org_locations = this.main_organisation_locations;
        const ids = value?.map(({ code }) => code);
        const isAvailable = _.difference(org_locations[name], ids).length === 0;
        if (!isAvailable) {
          this.showToast({
            class: "bg-danger text-white",
            message: "Not Allowed to Remove value",
          });
          return;
        }
      }
      Vue.set(this.customer, name, value);
      this.payload = {
        ...this.payload,
        [name]: value
          ? value.map((v) => {
              return v.id || v.code || v;
            })
          : null,
      };
      if (name === "country_ids") {
        this.customer["location_ids"] = this.customer.location_ids
          ? this.customer.location_ids.filter(({ country_id }) =>
              this.payload[name].includes(country_id)
            )
          : [];
        this.payload["location_ids"] = this.customer["location_ids"].map(
          (v) => v.id || v.code || v
        );
      }
    },
    cancelUpdate() {
      this.$router.push({ path: `/customer-list` });
    },
    async onSubmit() {
      const isValid = await this.$refs.editCustomer.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      let finalPayload = {
        ...this.payload,
      };
      finalPayload["customer_type_id"] !== 4
        ? delete finalPayload["org_type_id"]
        : null;
      let uploadPayload = {
        payload: finalPayload,
        customerId: this.customerId,
      };
      this.modalCallBack(true, uploadPayload);
    },
    clearFieldErrors(name, hasError) {
      this.$nextTick(() => {
        if (name === hasError.phone) {
          this.$refs.phone.errors.clear;
          this.$refs.phone.reset();
        }
      });
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },

    handleRadioButton(name, value) {
      let val = null;
      if (value === null) {
        val = null;
      } else if (value.id === false || value.code === false) {
        val = false;
      } else if (value.id === null || value.code === null) {
        val = null;
      } else {
        val = value.id || value.code || value;
      }

      Vue.set(this.customer, name, val);
      this.payload = {
        ...this.payload,
        [name]: val,
      };
    },
  },
  mounted() {
    this.initUserFetchOptions();
    const customerData = this.customerDetail;
    this.customer = {
      customer_type_id: customerData.customer_type
        ? {
            code: customerData.customer_type.customer_type_id,
            label: customerData.customer_type.comments,
          }
        : null,
      name: customerData.name,
      address: customerData.address,
      country_ids: this.main_organisation
        ? this.main_organisation.customer_organisation_location.map((v) => ({
            code: v?.location?.country?.country_id,
            label: v?.location?.country?.country_name,
          }))
        : [],
      location_ids: this.main_organisation
        ? this.main_organisation.customer_organisation_location.map((v) => ({
            code: v?.location?.location_id,
            label: v?.location?.location,
            country_id: v?.location?.country?.country_id,
          }))
        : [],
      district_ids: customerData?.customer_organisation
        ? _.uniqBy(
            _.flatMap(customerData?.customer_organisation, (val) =>
              _.map(val.customer_organisation_location, (e) =>
                e.district
                  ? {
                      code: e.district?.id,
                      label: e.district?.district,
                    }
                  : null
              ).filter((v) => v)
            ),
            "code"
          )
        : [],
      first_name: customerData.main_user?.first_name || null,
      last_name: customerData.main_user?.surname || null,
      phone: customerData.main_contact?.phone_no || null,
      email: customerData.main_user?.email || null,
      is_parent_admin: customerData?.main_organisation?.is_parent,
    };
    customerData?.customer_type_id === 4
      ? (this.customer["org_type_id"] = {
          code: customerData?.main_organisation?.organisation_type
            ?.organisation_type_id,
          label:
            customerData?.main_organisation?.organisation_type
              ?.organisation_type,
        })
      : null;
    this.customerId = customerData.customer_uid;

    this.payload["country_ids"] = this.customer["country_ids"].map(
      ({ code }) => code
    );
    this.payload["location_ids"] = this.customer["location_ids"].map(
      ({ code }) => code
    );
  },
};
</script>