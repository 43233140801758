<template>
  <div>
    <div class="mb-2" v-if="!isOrganisationFetch">
      <div v-if="organisations.length">
        <CDataTable
          :striped="striped"
          :bordered="bordered"
          :small="small"
          :items="organisations"
          :fields="fields"
          :items-per-page="limit"
          pagination
          class="border px-1"
        >
          <template #action="{ item }">
            <td>
              <CButton
                class="btn-outline-primary btn-sm btn-square"
                :disabled="item.path_acronym == '--'"
                v-c-tooltip="{
                  content: 'Config Theme',
                  placement: 'top',
                  appendToBody: true,
                }"
                @click="openConfigTheme(item)"
              >
                <em class="fas fa-tools"></em>
              </CButton>
            </td>
          </template>
        </CDataTable>
      </div>
      <h5 v-else class="h5 mt-3 mb-3 text-muted text-center">
        No {{ show_associated_orgs ? "Associated" : "Child" }} Organisation
        Found
      </h5>
    </div>
    <ConfigTheme
      v-if="configTheme.isShowPopup"
      :orgData="configTheme.item"
      :isShowPopup="configTheme.isShowPopup"
      :configModalCallBack="configModalCallBack"
      :updateOrgThemeManually="fetchOrganisationList"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ConfigTheme from "./ConfigTheme.vue";

export default {
  components: { ConfigTheme },
  props: {
    customer_uid: {
      type: Number,
    },
    parent_org_id: {
      type: Number,
    },
    is_recruiter_agency: {
      type: Boolean,
      default: false,
    },
    show_associated_orgs: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      limit: 4,
      small: true,
      striped: true,
      bordered: true,
      fields: [
        {
          key: "organisation_name",
          label: "Organisation Name",
          _style: "width:25%",
        },
        { key: "path_acronym", label: "Path Acronym", _style: "width:10%" },
        {
          key: "organisation_type",
          label: "Type",
          _style: "width:10%",
        },
        {
          key: "city",
          _style: "width:10%",
        },
        {
          key: "main_contact_name",
          label: "Main Contact Name",
          _style: "width:20%;white-space:nowrap",
        },
        { key: "email", label: "Email", _style: "width:20%" },
        {
          key: "action",
          label: "Action",
          sorter: false,
          filter: false,
          _style: "width:5%",
        },
      ],
      configTheme: {
        isShowPopup: false,
        orgData: {},
      },
    };
  },
  computed: {
    ...mapGetters(["isOrganisationFetch", "organisationList"]),
    organisations() {
      return (
        (this.organisationList?.length &&
          this.organisationList
            .filter((val) => {
              return this.is_recruiter_agency && this.show_associated_orgs
                ? val.is_associated && val.organisation_id != this.parent_org_id
                : !val.is_associated &&
                    val.organisation_id != this.parent_org_id;
            })
            .map(({ organisation, ...rest }) => ({
              organisation_name: organisation?.name,
              organisation_type:
                organisation?.organisation_type?.organisation_type,
              main_contact_name: organisation?.main_contact?.name,
              email: organisation?.main_contact?.email,
              city: rest?.customer_organisation_location
                ? rest?.customer_organisation_location
                    .map((val) => val?.location?.location)
                    .join(", ")
                : "--",
              path_acronym: rest?.path_acronym || "--",
              customer_uid: rest?.customer_uid,
              organisation_id: rest.organisation_id,
              theme_style: organisation?.theme_style,
              website: organisation?.website,
            }))
            .reverse()) ||
        []
      );
    },
  },
  methods: {
    ...mapActions(["fetchOrganisationListForCustomer"]),
    closeModal() {
      this.$emit("closeModal");
    },
    openConfigTheme(item) {
      this.tooltipHide();
      this.configTheme.item = item;
      this.configTheme.isShowPopup = true;
    },
    configModalCallBack() {
      this.configTheme.isShowPopup = false;
    },
    fetchOrganisationList() {
      this.fetchOrganisationListForCustomer({
        customer_uid: this.customer_uid,
        parent_org_id: !this.is_recruiter_agency ? this.parent_org_id : null,
      });
    },
    tooltipHide() {
      setTimeout(() => {
        let x = document.querySelectorAll(".tooltip");
        for (var i = 0; i < x.length; i++) {
          x[i].style.visibility = "hidden";
        }
      }, 50);
    },
  },
};
</script>
