<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">Edit associated Organisations</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          color="primary"
          variant="outline"
          class="px-4"
          @click="modalCallBack(false)"
          >Cancel</CButton
        >
        <CButton
          v-if="activeTab === 0"
          type="button"
          color="primary"
          class="px-4"
          @click="onSubmit"
          >Update</CButton
        >
        <CButton
          v-if="activeTab === 1"
          type="button"
          color="primary"
          class="px-4"
          @click="onSubmitDelete"
          >Delete</CButton
        >
      </template>
      <div>
        <CTabs variant="tabs" :active-tab.sync="activeTab">
          <CTab title="Add Association">
            <div class="p-2">
              <ValidationObserver
                ref="recruiterAgencyOrg"
                v-slot="{ handleSubmit }"
              >
                <form
                  id="recruiterAgencyOrg"
                  @submit.prevent="handleSubmit(onSubmit)"
                >
                  <CRow>
                    <CCol lg="12">
                      <CRow name="organisation" class="mb-3">
                        <label class="col-lg-12 col-md-12 required"
                          >Organisation</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <Select
                              :name="`organisation_ids`"
                              @input="handleChangeMultiSelectAsArray"
                              :value="customer.organisation_ids"
                              :options="
                                options && options['organisations']
                                  ? options['organisations']
                                  : []
                              "
                              :taggable="false"
                              :multiple="true"
                              :clearable="true"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                </form>
              </ValidationObserver>
            </div>
          </CTab>
          <CTab title="Delete Association">
            <div class="p-2">
              <ValidationObserver
                ref="recruiterAgencyOrgDelete"
                v-slot="{ handleSubmit }"
              >
                <form
                  id="recruiterAgencyOrgDelete"
                  @submit.prevent="handleSubmit(onSubmitDelete)"
                >
                  <CRow>
                    <CCol lg="12">
                      <CRow name="delete_organisation" class="mb-3">
                        <label class="col-lg-12 col-md-12 required"
                          >Organisation</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <Select
                              :name="`organisation_ids`"
                              @input="handleChangeMultiSelectAsArrayForDelete"
                              :value="deleteCustomer.organisation_ids"
                              :options="
                                options && options['existingOrganisation']
                                  ? options['existingOrganisation']
                                  : []
                              "
                              :taggable="false"
                              :multiple="true"
                              :clearable="true"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                </form>
              </ValidationObserver>
            </div>
          </CTab>
        </CTabs>
      </div>
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";

import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import PasswordInput from "@/components/reusable/Fields/PasswordInput";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import _ from "lodash";
import { extend } from "vee-validate";
import {
  required,
  email,
  confirmed,
  regex,
  min,
  numeric,
} from "vee-validate/dist/rules";
import { mapGetters, mapActions } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import m from "moment";

extend("required", { ...required, message: "This field is required" });

extend("numeric", { ...numeric, message: "Only numbers are allowed" });
extend("phone_rule", {
  ...regex,
  message: "Only numbers are allowed(Maximum of 15 digits)",
});
extend("email", { ...email, message: "Invalid email" });
export default {
  props: {
    customer_uid: {
      type: Number,
    },
    parent_org_id: {
      type: Number,
    },
    isShowPopup: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    Select,
    TextInput,
    PhoneInput,
    PasswordInput,
    RadioButton,
  },
  data() {
    return {
      payload: {},
      deletePayload: {},
      customer: {},
      deleteCustomer: {},
      activeTab: 0,
    };
  },
  computed: {
    ...mapGetters(["getAllOrganisationList", "organisationList"]),
    getOrganisationIds() {
      return (
        this.organisationList.length
        ? this.organisationList.map((val) => val.organisation_id)
        : []
      );
    },
    organisationListForOption() {
      return this.getAllOrganisationList
          ? this.getAllOrganisationList.filter((val) => {
            return val.organisation_id != this.parent_org_id && val?.organisation_type_id != 3 // 3 - Recruiter Agency
          }
          ).map((val) => ({
            label: val.name,
            code: val.organisation_id
          }))
        : []
    },
    associatedOrganisationListForOption() {
      return this.organisationList
          ? this.organisationList.filter((val) => {
            return this.parent_org_id 
            ? val.organisation_id != this.parent_org_id
            : val
          }
          ).map((val) => ({
            label: val.organisation.name,
            code: val.organisation_id
          }))
        : []
    },
    options() {
      return {
        organisations: this.organisationListForOption || [],
        existingOrganisation: this.associatedOrganisationListForOption || [],
      };
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "fetchAllOrganisationList",
      "fetchOrganisationListForCustomer",
    ]),
    handleInput(name, value) {
      let hasError = {
        phone: "phone",
      };
      if (name !== hasError.phone || (name == hasError.phone && value !== "")) {
        Vue.set(this.customer, name, value);
        Vue.set(this.payload, name, value);
      }
      if (name === hasError.phone && value === "") {
        this.clearFieldErrors(name, hasError);
      }
    },
    handleChangeSelect(name, value) {
      Vue.set(this.customer, name, value);
    },
    handleChangeMultiSelectAsArray(name, value) {
      if (
        name === "organisation_ids" &&
        value &&
        value.some(({ code }) => this.getOrganisationIds.includes(code))
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message:
            "Organisation already exists! Please select another Organisation",
        });
        return;
      }

      Vue.set(this.customer, name, value);

      this.payload = {
        ...this.payload,
        [name]: value
          ? value.map((v) => {
              return v.id || v.code || v;
            })
          : null,
      };
    },
    handleChangeMultiSelectAsArrayForDelete(name, value) {
      Vue.set(this.deleteCustomer, name, value);

      this.deletePayload = {
        ...this.deletePayload,
        [name]: value
          ? value.map((v) => {
              return v.id || v.code || v;
            })
          : null,
      };
    },
    cancelUpdate() {
      this.$router.push({ path: `/customer-list` });
    },
    async onSubmit() {
      const isValid = await this.$refs.recruiterAgencyOrg.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      this.payload.organisation_ids = this.payload.organisation_ids.filter((org_id) => {
        return !this.getOrganisationIds.includes(org_id)
      })
      let uploadPayload = {
        payload: this.payload,
        create: true,
      };
      this.modalCallBack(true, uploadPayload);
    },
    async onSubmitDelete() {
      const isValid = await this.$refs.recruiterAgencyOrgDelete.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      let uploadPayload = {
        payload: this.deletePayload,
        create: false,
      };
      this.modalCallBack(true, uploadPayload);
    },
    clearFieldErrors(name, hasError) {
      this.$nextTick(() => {
        if (name === hasError.phone) {
          this.$refs.phone.errors.clear;
          this.$refs.phone.reset();
        }
      });
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },

    handleRadioButton(name, value) {
      let val = null;
      if (value === null) {
        val = null;
      } else if (value.id === false || value.code === false) {
        val = false;
      } else if (value.id === null || value.code === null) {
        val = null;
      } else {
        val = value.id || value.code || value;
      }

      Vue.set(this.customer, name, val);
      this.payload = {
        ...this.payload,
        [name]: val,
      };
    },
  },
  mounted() {
    this.fetchAllOrganisationList();
    this.fetchOrganisationListForCustomer({
      customer_uid: this.customer_uid,
      // parent_org_id: this.parent_org_id,
    });
  },
};
</script>